import TournamentsRequest from '@/@api/tournaments'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const tournaments = ref([])

export default function useTournaments() {
  // Use toast
  const toast = useToast()

  const refTournamentListTable = ref(null)

  // const tournaments = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    {
      label: 'Torneo',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalTournaments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refTournamentListTable.value ? refTournamentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTournaments.value,
    }
  })

  const refetchData = () => {
    refTournamentListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchTournaments = (ctx, callback) => {
    TournamentsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(tournament => ({ ...tournament, loading: false })))
        totalTournaments.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching tournaments list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // async function fetchTournamentsSelector(isMemo = false) {
  //   if (isMemo && tournaments.value.length) {
  //     return Promise.resolve(tournaments.value)
  //   }

  //   try {
  //     const { data } = await TournamentsRequest.select()

  //     const list = data.data.map(tournament => ({
  //       id: tournament.id,
  //       value: tournament.iso?.toUpperCase() || tournament.id,
  //       label: tournament.name,
  //     }))

  //     tournaments.value = list

  //     return list
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // }

  async function tournamentShow(id) {
    try {
      const { data } = await TournamentsRequest.show(id)

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: data.message,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
      return data.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async function tournamentStore(body) {
    try {
      const { data } = await TournamentsRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function tournamentUpdate(id, body) {
    try {
      const { data } = await TournamentsRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function tournamentDelete(id) {
    try {
      const { data } = await TournamentsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // fetchTournamentsSelector(true).then(list => {
  //   tournaments.value = list
  // })

  // const resolveIsoTournament = iso => {
  //   if (!iso) return ''

  //   const dic = tournaments.value.reduce((acc, tournament) => {
  //     acc[tournament.value] = tournament.label

  //     return acc
  //   }, {})

  //   return dic[iso] ?? iso
  // }

  return {
    tournaments,
    fetchTournaments,
    // fetchTournamentsSelector,
    tableColumns,
    perPage,
    currentPage,
    totalTournaments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTournamentListTable,

    // resolveIsoTournament,

    refetchData,
    tournamentShow,
    tournamentStore,
    tournamentUpdate,
    tournamentDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
