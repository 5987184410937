<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <!-- @reset.prevent="resetForm" -->
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div class="flex-grow-1 pr-3">
                    <validation-provider
                      #default="validationContext"
                      name="Título"
                      rules="required"
                    >
                      <b-form-group
                        label="Título"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="tournamentData.name"
                          :state="getValidationState(validationContext)"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <!-- Header: Right Content -->
                  <div
                    class="invoice-number-date mt-md-0 mt-2"
                    style="min-width: 220px;"
                  >
                    <div
                      class="d-flex align-items-center"
                      :style="isDragging ? 'pointer-events: none' : ''"
                    >
                      <b-form-group
                        label="Importar Excel"
                        label-for="file"
                      >
                        <b-form-file
                          id="file"
                          ref="refInputGlobalEl"
                          accept=".xlsx, .xls, .csv"
                          placeholder="Arraste y suelte o haga clic para subir el excel"
                          multiple
                          :disabled="loading"
                          @change="changeInputFile"
                        />
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="3"
                    class="border-right border-right-2"
                  >
                    <h5>Cabezeras</h5>
                    <b-list-group>
                      <b-list-group-item
                        v-for="(head, key) in headers"
                        :key="key"
                        class="d-flex justify-content-between align-items-center"
                        @click="() => toogleField(head)"
                      >
                        <b-form-checkbox
                          :id="`checkbox-${head.label}`"
                          v-model="head.selected"
                          :name="`checkbox-${head.label}`"
                          class="pointer-events-none"
                        >
                          {{ head.label }}
                        </b-form-checkbox>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="9"
                    style="min-height: 160px"
                  >
                    <h5>Datos</h5>

                    <div
                      v-if="values.length"
                      class="p-0 overflow-x-scroll border border-1 rounded-sm"
                    >
                      <b-table
                        :items="valuesFieldsActive"
                        hover
                      />
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <div class="mb-2">
            <validation-provider
              #default="validationContext"
              name="Donde mostrar"
              rules="required"
            >
              <b-form-group
                label="Donde mostrar"
                label-for="where-show"
              >
                <b-form-tags
                  id="where-show"
                  v-model="tournamentData.whereShow"
                  input-id="where-show"
                  :state="getValidationState(validationContext)"
                  :disabled="loading"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <div class="mb-2">
            <validation-provider
              #default="validationContext"
              name="Selector CSS"
              rules="required"
            >
              <b-form-group
                label="Selector CSS"
                label-for="css-selector"
              >
                <b-form-tags
                  id="css-selector"
                  v-model="tournamentData.cssSelector"
                  input-id="css-selector"
                  :state="getValidationState(validationContext)"
                  :disabled="loading"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>

          <hr class="invoice-spacing">

          <div
            class="d-flex justify-content-between align-items-center my-1"
          >
            <label
              for="allRequiredAuth"
              class="w-100 d-flex justify-content-between mr-1 font-small-3"
            >Activo <span>{{ tournamentData.status ? 'SI' : 'NO' }}</span></label>
            <b-form-checkbox
              id="allRequiredAuth"
              v-model="tournamentData.status"
              :disabled="loading"
              switch
            />
          </div>

          <hr class="invoice-spacing">

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            @click="onSubmit"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            block
            type="button"
            @click="saveAndNew"
          >
            <template v-if="loading">
              <b-spinner
                class="mr-1"
                label="Small Spinning"
                small
              />
              Guardando...
            </template>
            <template v-else>
              Guardar y crear otro
            </template>
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <backdrop-files
      v-if="isDragging"
      text="Suelta el excel aquí"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  // BFormTextarea,
  BFormCheckbox,
  // VBToggle,
  BFormTags,
  BFormInput,
  // BMedia,
  // BCardText,
  // BMediaAside,
  // BMediaBody,
  // BImg,
  BFormFile,
  // BLink,
  BSpinner,
  // BSidebar,
  // BForm,
  // BFormGroup,
  // BFormInput,
  BFormInvalidFeedback,
  // BButton,
  // BFormCheckbox,
  // BSpinner,
  BListGroup,
  BListGroupItem,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { read, utils } from 'xlsx'
import { onMounted, ref, computed } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@/@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import iconsList from '@/@fake-db/data/other/icons'
// import useLangs from '@/views/langs/useLangs'
import BackdropFiles from '@/views/banner/BackdropFiles.vue'
import useTournaments from '../useTournaments'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    // BFormTextarea,
    BFormCheckbox,
    // VBToggle,
    BFormTags,
    BFormInput,
    // BMedia,
    // BCardText,
    // BMediaAside,
    // BMediaBody,
    // BImg,
    BFormFile,
    // BLink,
    BSpinner,
    // BSidebar,
    // BForm,
    // BFormGroup,
    // BFormInput,
    BFormInvalidFeedback,
    // BButton,
    // BFormCheckbox,
    // BSpinner,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BackdropFiles,
    BListGroup,
    BListGroupItem,
    BTable,
  },
  directives: {
    Ripple,
  },
  model: {
    // prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    // isAddNewUserSidebarActive: {
    //   type: Boolean,
    //   required: true,
    // },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      iconsList,
    }
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
    // console.log('mounted')
    window.addEventListener('dragover', this.dragover)
    window.addEventListener('dragleave', this.dragleave)
    window.addEventListener('drop', this.drop)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
    // console.log('destroyed')
    window.removeEventListener('dragover', this.dragover)
    window.removeEventListener('dragleave', this.dragleave)
    window.removeEventListener('drop', this.drop)
  },
  methods: {
    // Methods Drag and drop file
    dragover(e) {
      e.preventDefault()
      this.isDragging = true
    },
    dragleave() {
      this.isDragging = false
    },
    drop(e) {
      e.preventDefault()
      this.changeInputFile(e.dataTransfer.files)
      this.isDragging = false
    },
    async changeInputFile(e) {
      let inputFiles = []

      if (e instanceof Event) {
        inputFiles = e.target.files
      } else if (e instanceof FileList) {
        inputFiles = e
      } else if (Array.isArray(e)) {
        inputFiles = e
      } else {
        inputFiles = this.$refs.refInputGlobalEl?.files ?? []
      }

      const file = inputFiles[0]
      // const name = file.name
      // console.log(file)

      const reader = new FileReader()

      // eslint-disable-next-line no-shadow
      reader.onload = e => {
        const data = e.target.result

        const workbook = read(data, { type: 'binary' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const json = utils.sheet_to_json(worksheet, { defval: null, raw: false })

        this.headers = Object.keys(json[0]).map(key => ({
          label: key,
          selected: false,
        }))
        this.data = json
        this.values = json.slice(0, 10)

        this.$refs.refInputGlobalEl.reset()
      }
      reader.readAsBinaryString(file)
    },
  },
  setup() {
    const { route } = useRouter()

    const blankData = {
      id: null,
      name: '',
      file: null,
      whereShow: [],
      cssSelector: [],
      status: true,
    }

    const tournamentData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      tournamentData.value = JSON.parse(JSON.stringify(blankData))
    }

    const loading = ref(false)
    const isDragging = ref(false)
    const refInputGlobalEl = ref(null)

    const headers = ref([])
    const values = ref([])
    const data = ref([])

    const { tournamentShow, tournamentStore, tournamentUpdate } = useTournaments()

    const fields = computed(() => headers.value.filter(i => i.selected))
    const valuesFieldsActive = computed(() => values.value.map(v => {
      const keys = Object.keys(v).filter(key => fields.value.some(field => field.label === key))
      const value = {}

      keys.forEach(key => {
        value[key] = v[key]
      })

      return value
    }))

    onMounted(async () => {
      const { id } = route.value.params

      if (!id) return
      // const item = await tournamentShow(id)
      tournamentShow(id).then(item => {
        if (!item) return
        tournamentData.value = {
          ...item,
          whereShow: JSON.parse(item.whereShow),
          cssSelector: JSON.parse(item.cssSelector),
        }

        headers.value = JSON.parse(item.headers)
        values.value = JSON.parse(item.values)
        data.value = item.data ? JSON.parse(item.data) : []
      })

      // const langList = await fetchLangsSelector()
      // languages.value = langList
    })

    const onSubmit = async () => {
      if (loading.value) return

      loading.value = true

      const form = { ...tournamentData.value }
      form.whereShow = JSON.stringify(form.whereShow)
      form.cssSelector = JSON.stringify(form.cssSelector)
      form.headers = JSON.stringify(headers.value)
      form.values = JSON.stringify(values.value)
      form.data = JSON.stringify(data.value)

      try {
        if (form.id) {
          await tournamentUpdate(form.id, form)
        } else {
          await tournamentStore(form)
        }

        router.back()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const saveAndNew = async () => {
      if (loading.value) return

      loading.value = true

      const form = { ...tournamentData.value }

      try {
        if (form.id) {
          await tournamentUpdate(form.id, form)
        } else {
          await tournamentStore(form)
        }

        tournamentData.value = JSON.parse(JSON.stringify({ ...blankData }))
        headers.value = []
        values.value = []
        data.value = []
      } catch (error) {
        console.log(error)
        tournamentData.value = JSON.parse(JSON.stringify({ ...blankData }))
        headers.value = []
        values.value = []
        data.value = []
      } finally {
        loading.value = false
      }
    }

    const toogleField = header => {
      headers.value = headers.value.map(h => {
        if (h.label === header.label) {
          // eslint-disable-next-line no-param-reassign
          h.selected = !h.selected
        }
        return h
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    // const addNewItemInUrlForm = () => {
    //   tournamentData.value.urls.push({ ...itemFormBlankUrl })
    // }

    // const removeLang = index => {
    //   tournamentData.value.urls.splice(index, 1)
    // }

    return {
      tournamentData,
      loading,
      onSubmit,
      saveAndNew,
      // addNewItemInUrlForm,
      // removeLang,
      // languages,
      headers,
      values,
      data,
      fields,
      toogleField,
      valuesFieldsActive,

      refFormObserver,
      getValidationState,
      resetForm,
      isDragging,
      refInputGlobalEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
